@import '../../variables.scss';
@import '../../mobile.scss';
@import '../variables.scss';

.virtual-list-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  @include phone {
    display: flex !important;
    margin-bottom: 0 !important;
  }
  @include tablet {
    display: flex !important;
    margin-bottom: 0 !important;
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    width: 100%;
    text-align: center;
    background-color: $extralightgray;
    border-bottom: 1px solid #979797;
    cursor: default;

    .header {
      display: flex;
      align-items: center;
      color: $darkpurple;
      font-size: 18px;
      font-weight: bold;
      font-family: 'DIN', sans-serif;
      @include phone {
        width: 100%;
      }
      @include tablet {
        width: 100%;
      }

      .resultsWrapper {
        display: flex;
        background-color: white;
        padding: 0.25em;
        border-radius: 25px;
        font-size: 16px;
        border: 1px solid #6e3694;

        @include phone {
          flex: 2 2 auto;
          padding: 0;
          border: none;
          background: none;
        }
        @include tablet {
          flex: 2 2 auto;
          padding: 0;
          border: none;
          background: none;
        }

        .result-button {
          background-color: rgba(#ded3e8, 0.3);
          border-radius: 16px !important;

          text-transform: none;

          font-family: DIN;
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 19px;
          color: #2c0040;
          padding: 7px 10px;
          margin: 5px;
          @include phone {
            font-size: 14px;
            line-height: 17px;
            width: 50%;
          }
          @include tablet {
            font-size: 14px;
            line-height: 17px;
            width: 50%;
          }

          .MuiButton-label {
            opacity: 0.6;
            @include phone {
              > span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }

          &.active {
            background-color: #6e3694;
            color: white;
            opacity: 1;

            .MuiButton-label {
              opacity: 1;
            }
          }
        }
      }

      .resultOptions {
        display: flex;
        flex: 0 1 auto;
      }
    }

    .mapView {
      color: $blackcurrant;
      font-size: 18px;
      text-align: center;
      cursor: pointer;
      font-family: 'DIN', sans-serif;
      color: $darkpurple;
    }
  }

  @media (min-width: $mobilesize) {
    .top {
      display: flex;
    }
  }

  .divider {
    height: 2px;
    width: 100%;
    background-color: $gray;
  }

  .searchMsg {
    font-family: 'DIN';
    text-align: center;
    color: #6e3694;
    font-weight: 700;
    max-width: 80%;
    margin: 24px auto;
  }

  .bottom {
    height: calc(100% - 75px);
    width: 100%;
    display: flex;
    @include phone {
      height: auto;
      flex: 4 0 auto;
    }
    @include tablet {
      height: auto;
      flex: 4 0 auto;
    }
  }
}
