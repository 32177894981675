@import '../../pages/variables.scss';
@import '../../pages/mobile.scss';

.aboutPage {
  overflow: scroll;
  .purple {
    background-color: #e2d7ea;
  }

  h1 {
    color: #6e3694;
    font-size: 42px;
    font-family: 'Inter';
  }

  h2 {
    color: #6e3694;
    font-size: 34px;
    font-family: 'Inter';
    margin-top: 10px;
  }

  h3 {
    color: #6e3694;
    font-size: 30px;
    font-family: 'Inter';
    margin-top: 10px;
    text-align: center;
    &.white {
      color: #fff;
    }
  }

  h5 {
    color: #6e3694;
    font-size: 18px;
    font-family: 'Inter';
    margin-bottom: 8px;
  }

  .textContainer {
    width: 90%;
    max-width: 900px;
    padding: 100px 0;
    margin: auto;
    .aboutFTG {
      max-width: 700px;
      width: 100%;
      font-weight: 700;
      p {
        line-height: 1.5;
        font-size: 22px;
      }
    }
    &.flex {
      // padding: 0 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @media (max-width: 650px) {
        flex-direction: column;
      }
      .forty {
        width: 39%;
      }
      .sixty {
        width: 55%;
      }
      .half {
        width: 49%;
        @media (max-width: 650px) {
          width: 100%;
        }
        .people {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 30px;
          @media (max-width: 500px) {
            flex-direction: column;
          }
          .list {
            width: 32%;
            @media (max-width: 500px) {
              width: 100%;
            }
            p {
              margin: 4px 0;
            }
          }
        }
      }
    }
  }

  .yellow {
    background-color: #fff5e4;
    padding: 60px 0 20px;
  }

  .photoContainer {
    width: 90%;
    max-width: 1000px;
    margin: 40px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 650px) {
      flex-direction: column;
    }
    .person {
      width: 32%;
      img {
        width: 100%;
      }
      @media (max-width: 650px) {
        width: 100%;
      }
    }
  }

  .darkPurple {
    background-color: #2c0040;
    .surveyContainer {
      padding: 100px 0;
      width: 90%;
      max-width: 600px;
      padding: 100px 0;
      margin: auto;
      text-align: center;
      p {
        color: #fff;
      }
      button {
        background: #fff;
        border-radius: 20px;
        font-family: 'Inter';
        text-transform: uppercase;
        color: #2c0040;
        text-align: center;
        border-width: 0px;
        max-width: 300px;
        width: 100%;
        margin-left: 0px;
        margin-top: 15px;
        cursor: pointer;
      }
    }
  }
}
