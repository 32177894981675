@import '../../components/GlobalStyles/variables.scss';
@import '../../components/GlobalStyles/colors.scss';
@import '../../components/GlobalStyles/pods.scss';
@import '../../components/GlobalStyles/buttons.scss';

.wrapper {
  z-index: 5 !important;
  padding: 30px;

  .input {
    width: 100%;
  }

  Button {
    background-color: $alt-font-color-1;
    color: white;
    font-family: 'Oswald' !important;
    font-size: 16px;
    line-height: 16px;
    padding: 10px;

    &:disabled {
      background-color: $disabled-button-color;
    }
  }

  .buttonWrapper {
    display: flex;
    flex-direction: row-reverse;
  }

  .labelWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 15px !important;
  }

  .label {
    color: $dark-font-color;
    font-family: 'Oswald';
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    flex: 1;
  }
}
