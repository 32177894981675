@import url('https://fonts.googleapis.com/css?family=Oswald&display=swap');
@import url('https://fonts.googleapis.com/css?family=Archivo Narrow&display=swap');
@import '/components/GlobalStyles/colors.scss';
@import '/components/GlobalStyles/pods.scss';

html,
body,
#root {
  height: 100%;
}

.Pods {
  font-family: Oswald;
  display: flex;
  flex-direction: column;
  // min-height: 100%;
  height: 100%;
  background-color: $backgroundColor;

  .container {
    color: green;
  }
}

.screenCover {
  display: none;
  padding: 10px;

  .userMessageBox {
    font-family: 'Archivo', sans-serif;
    text-align: center;
    //max-width: $userMessageWidth;
    margin-top: auto;
    margin: auto;

    padding: 20px 0 20px 0;

    margin-top: 45px;
    max-width: 93%;
  }

  .userMessageHeader {
    color: $dark-font-color;
    font-family: 'Archivo Narrow';
    font-size: 20px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 27px;
    text-align: center;
    padding: 20px;
  }

  .userMessageMessage {
    color: $text-font-color;
    font-family: 'Archivo Narrow';
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
  }
}

@media only screen and (max-width: 812px) {
  .screenCover {
    display: block;
    position: fixed;
    z-index: 100000000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $lighterpurple;
  }
}

.showAnyway {
  display: none !important;
}
