@import '../GlobalStyles/colors.scss';
@import '../../pages/variables.scss';
@import '../../pages/mobile.scss';

.filter-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 20px;
  min-width: 200px;
  max-width: 220px;

  @include phone {
    max-width: none;
    padding-left: 0px;
    margin: 0;
  }
  @include tablet {
    max-width: none;
    padding-left: 0px;
    margin: 0;
  }

  .filter-label {
    color: #2c0040;
    font-family: DIN;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 14px;

    @include phone {
      font-size: 14px;
      font-family: DIN Bold;
      font-weight: normal;
    }

    @include tablet {
      font-size: 14px;
      font-family: DIN Bold;
      font-weight: normal;
    }
  }

  .filter-auto-wrapper {
    @include phone {
      padding-bottom: 10px;
    }

    @include tablet {
      padding-bottom: 10px;
    }
    .filter-auto {
      .MuiInputBase-root {
        border: 1px solid $anotherPurple;
        border-radius: 22px !important;
        background-color: #ffffff;
        padding-right: 10px;
        height: 35px;

        input {
          color: #5c5c5d;
          letter-spacing: 0;
          &::placeholder {
            color: #5e5e5e;
            opacity: 1;
            font-family: DIN !important;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 19px;
            white-space: nowrap;
            font-style: italic;
          }
        }
      }

      fieldset {
        border: none;
        outline: none;
      }

      &.wider {
        .MuiInputBase-root {
          padding-right: 14px;
        }
      }
    }
  }

  .close-button {
    height: 20px;
    width: 20px;
    border-radius: 50% !important;

    img {
      height: 20px;
      width: 20px;
    }
  }
}
