@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

.App {
  display: flex;
  flex-direction: column;
  position: fixed;
  // height: 100%;
  width: 100%;
  // overflow: hidden;
  height: 100vh;
  font-family: 'DIN', sans-serif;
}

.languageMenu {
  margin-top: 5px;
  width: 115px;
  ul {
    background-color: white;
    li {
      color: #2c0040;

      &:hover,
      &.Mui-selected {
        font-weight: bold;
      }
    }
  }
}
