@import '../../components/GlobalStyles/colors.scss';
@import '../../components/GlobalStyles/pods.scss';
@import '../../components/GlobalStyles/variables.scss';
@import '../../components/GlobalStyles/buttons.scss';

.desktopOnly {
  display: none;
}

.stats {
  position: absolute;
  bottom: 0;
  width: 290px;
  height: 70px;
  padding: 15px 20px;
  background-color: #2c0040;
  margin: 35px 20px;
  font-family: Oswald;
  font-size: 15px;
  font-weight: 700;
  color: $light-font-color;
  z-index: 5;

  .left {
    float: left;
    padding-right: 20px;
    text-align: center;
  }

  .middle {
    float: left;
    border-left: 1px solid;
    padding-left: 30px;
    text-align: center;
  }

  .right {
    float: right;
    border-left: 1px solid;
    padding-left: 20px;
    text-align: center;
  }

  .stat {
    color: $alt-font-color-1;
    font-size: 16px;
  }
}

.leftpanel {
  position: absolute;
  width: 375px;
  max-height: calc(100% - 136px);
  background-color: $white;
  margin-left: 20px;
  margin-top: 20px;
  min-height: 200px;
  z-index: 5;
  overflow: hidden;
}

.newpod {
  position: absolute;
  width: 345px;
  min-height: 150px;
  // max-height: calc(100% - 400px);
  background-color: $white;
  bottom: 60px;
  right: 10px;
  z-index: 5;
}

@media (min-width: $mobilesize) {
  .desktopOnly {
    display: block;
  }
}

.legend {
  font-size: 14px;
  font-weight: 500;
  color: $light-font-color;
  position: absolute;
  right: 50px;
  top: 11px;
  padding: 14.5px;
  z-index: 5;
  background-color: #2c0040;
  font-family: 'Archivo Narrow';

  .legendItemTop {
    padding-bottom: 4px;
  }

  .legendItem {
    display: flex;
    flex-direction: row;
    height: 1.5rem;

    .inactive {
      padding-right: 9px;
      margin-top: 2px;
      margin-left: -1px;
      height: 18px;
    }

    .pod {
      color: $alt-font-color-1;
      padding-right: 10px;
      margin-top: 6px;
    }

    .myPod {
      margin-right: 6px;
      margin-top: 3px;
      width: 20px;
    }

    .legendText {
      font-family: Oswald;
      font-size: 14px;
      font-weight: 500;
      color: $light-font-color;
    }
  }
}
