$white: #ffffff;
$extralightgray: #f4f4f4;
$lightergray: #dbdbdb;
$lightgray: #cccccc;
$gray: #979797;
$darkpurple: #2c0040;
$purple: #6e3694;
$blackcurrant: #4a4a4a;
$black: #000000;
$offblack: #1a1a1a;
$orange: #efac42;
$darkorange: #e79107;
$red: #ef4044;
