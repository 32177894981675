.App {
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.languageMenu {
  margin-top: 5px;
  width: 115px;
  ul {
    background-color: white;
    li {
      color: #2c0040;

      &:hover,
      &.Mui-selected {
        font-weight: bold;
      }
    }
  }
}
