$page-background-color: rgba(245, 247, 254, 1);
$dark-background-color: rgba(44, 0, 64, 0.7);
$medium-background-color: #6e3694;

$dark-font-color: #2c0040;
$medium-font-color: #6e3694;
$light-font-color: #e0d5ea;
$alt-font-color-1: #efac42;
$text-font-color: rgba(26, 26, 26, 0.7);

$disabled-button-color: rgba(56, 56, 56, 0.4);
