@import '../../components/GlobalStyles/colors.scss';
@import '../../components/GlobalStyles/buttons.scss';

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: $backgroundColor;
  flex-shrink: 0;
  height: 100%;

  .tableHeader {
    color: $anotherPurple;
    font-weight: 500;
    font-family: Oswald;
    font-size: 1rem;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    text-align: left;
  }

  .tableCell {
    text-align: left;
  }
}

.primary {
  color: rgba(26, 26, 26, 0.9);
  font-family: 'Archivo Narrow';
  font-size: 1rem;
  font-weight: 700;
}

.cellCenter {
  text-align: center;
}

.marginCenter {
  margin: auto !important;
}

.attention {
  padding-left: 8px;
}

.check {
  margin-top: 15px;
}
