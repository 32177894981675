@import '../../components/GlobalStyles/pods.scss';
@import '../../components/GlobalStyles/buttons.scss';

.root {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.header {
  h5 {
    color: #2c0040;
    font-family: Archivo Narrow;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0px;
  }
}

.body {
  .searchInput {
    width: 100%;

    & > div {
      max-height: 42px;
    }

    i {
      font-size: 17px;
    }

    input {
      font-family: 'Oswald' !important;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 16px;
      color: $text-font-color;
    }

    margin-bottom: 16px;
  }
}

.content {
  overflow-y: auto;
  position: relative;
  max-height: calc(100vh - 450px);

  ul {
    padding-top: 0;
    padding-bottom: 8px;
    color: rgba(26, 26, 26, 0.7);
  }
}

.helperText {
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: 'Archivo Narrow' !important;
  font-size: 16px;
  letter-spacing: 0;
  color: $text-font-color;
}

.globalText {
  padding-top: 8px;
  padding-bottom: 8px;
  font-family: 'Archivo Narrow' !important;
  font-size: 16px;
  color: $text-font-color;

  text-align: left;
  letter-spacing: 0.2px;
  line-height: 1.4;
}

.footer {
  h6 {
    color: #2c0040;
    padding-bottom: 8px;
    font-family: 'Archivo Narrow';
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.2px;
    line-height: 22px;
  }
}

.inactive {
  color: #9477a7;
  padding-right: 12px;
  margin-top: 6px;
}

.pod {
  color: $alt-font-color-1;
  padding-right: 10px;
  margin-top: 6px;
}

.myPod {
  margin-right: 6px;
  margin-top: 3px;
  width: 20px;
}
