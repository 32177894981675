@mixin phone-portrait {
  @media screen and (max-width: 450px) and (orientation: portrait) {
    @content;
  }
}

@mixin phone-landscape {
  @media screen and (max-height: 414px) and (orientation: landscape) {
    @content;
  }
}

@mixin phone {
  @media screen and (max-width: 450px) and (orientation: portrait),
    screen and (max-height: 414px) and (orientation: landscape) {
    @content;
  }
}

@mixin tablet-portrait {
  @media screen and (min-width: 451px) and (max-width: 800px) and (orientation: portrait) {
    @content;
  }
}

@mixin tablet-landscape {
  @media screen and (min-width: 700px) and (max-width: 1023px) and (orientation: landscape) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: 451px) and (max-width: 800px) and (orientation: portrait),
    screen and (min-width: 700px) and (max-width: 1023px) and (orientation: landscape) {
    @content;
  }
}
