$white: rgba(255, 255, 255, 1);
$lightblue: rgba(54, 179, 168, 1);
$swansdown: rgba(221, 233, 230, 1);
$lavender: rgba(245, 247, 254, 1);
$backgroundColor: #f9f9f8;
$shamrock: rgba(54, 179, 168, 1);
$blackcurrant: rgba(29, 20, 53, 1);
$lightmint: rgba(221, 233, 230, 1);
$offwhite: rgba(251, 252, 255, 1);
$yellowwhite: #dfe1da;
$anotherPurple: #6e3694;
$black: rgba(0, 0, 0, 1);
$darkpurple: rgba(96, 59, 144, 1);
$darkerpurple: #2c0040;
$mediumpurple: rgba(113, 59, 144, 1);
$lightpurple: rgba(143, 50, 146, 1);
$lighterpurple: #e0d5ea;
$menucolor: rgba(44, 0, 64, 0.2);
$menuactive: rgba(44, 0, 64, 0.7);
$lightgray: rgba(93, 115, 126, 1);
$teal: rgba(54, 179, 168, 1);
$mountainmeadow: rgba(18, 155, 143, 1);
$blackpurple: rgba(22, 15, 41, 1);
$graywhite: rgba(238, 238, 238, 1);
$lightgraywhite: rgba(204, 204, 204, 1);
$cloudygray: rgba(216, 216, 216, 1);
$lightestbabyblue: rgba(251, 252, 255, 1);
$eggshell: rgba(249, 249, 249, 1);
$seafoam: rgba(163, 196, 188, 1);
$darkteal: rgba(16, 132, 122, 1);
$raspberry: rgba(166, 58, 170, 1);
$darkraspberry: rgba(110, 8, 113, 1);
$rainy: rgba(229, 231, 237, 1);
$cottoncandy: rgba(255, 241, 255, 1);
$sharpred: #ef4044;
$disabled-button-color: rgba(56, 56, 56, 0.65);
