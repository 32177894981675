@import '../../components/GlobalStyles/colors.scss';
@import '../../components/GlobalStyles/buttons.scss';

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: $backgroundColor;
  flex-shrink: 0;
  height: 100%;

  .tableHeader {
    color: $anotherPurple;
    font-weight: 500;
    font-family: Oswald;
    font-size: 1rem;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    text-align: left;
  }

  .tableCell {
    text-align: left;
    font-family: 'Archivo Narrow';
  }

  .tableBody {
    color: rgba(26, 26, 26, 0.9);
    font-family: 'Archivo Narrow';
    font-size: 1rem;
    font-weight: 500;
  }

  .attention {
    padding-left: 8px;
    vertical-align: sub;
    font-family: 'Archivo Narrow';
  }

  .archivo {
    font-family: 'Archivo Narrow';
  }

  .star {
    padding-left: 8px;
    color: #efac42;
  }
}
