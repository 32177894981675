@import '../GlobalStyles/colors.scss';
@import '../../pages/variables.scss';
@import '../../pages/mobile.scss';

.filter-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 20px;
  min-width: 200px;
  max-width: 220px;
  margin-top: -10px;

  @include phone {
    max-width: none;
    padding-left: 0px;
    margin: 0;
  }
  @include tablet {
    max-width: none;
    padding-left: 0px;
    margin: 0;
  }

  .filter-label {
    color: #2c0040;
    font-family: DIN;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 14px;
    padding-bottom: 3px;

    @include phone {
      font-size: 14px;
      font-family: DIN Bold;
      font-weight: normal;
    }
    @include tablet {
      font-size: 14px;
      font-family: DIN Bold;
      font-weight: normal;
    }
  }

  .filter-select-wrapper {
    padding-bottom: 20px;
    @include phone {
      padding-bottom: 10px;
    }
    @include tablet {
      padding-bottom: 10px;
    }
    .filter-select {
      border-radius: 16px;
      min-width: 119px;
      border: 1px solid $anotherPurple;
      border-radius: 22px;
      background-color: #ffffff;
      padding-left: 10px;
      padding-right: 10px;

      &.MuiInputBase-root {
        border-radius: 18px !important;

        &::before {
          border: none;
        }
        &::after {
          border: none;
        }
      }

      .Mui-disabled {
        opacity: 0.5;
      }

      .MuiSelect-root {
        padding-top: 7px;
        padding-bottom: 7px;
        border-radius: 2px !important;
        // color: #5c5c5d;
        font-family: DIN !important;
        font-size: 16px;
        // font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        color: #6e3694;
        font-weight: 700;

        &:focus {
          background-color: unset;
        }
      }
    }
  }

  .MuiSelect-root {
    font-family: DIN;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
  }

  em {
    font-style: normal;
    color: #767676;
    font-weight: 500;
  }
}

.MuiListSubheader-sticky {
  position: unset !important;
}

.disabled-holder {
  opacity: 0.5;
}

.list {
  .MuiMenuItem-root {
    font-family: 'DIN';
    color: #767676;
    font-weight: 500;

    &:hover {
      background-color: #f5f2f8 !important;
    }
  }

  .Mui-selected,
  .Mui-selected:hover {
    color: #6e3694;
    background-color: #f5f2f8 !important;
    font-weight: 700;
  }
}

.paper {
  margin-top: 8px;
  box-shadow: 0 4px 16px 4px rgba(44, 0, 64, 10%);
  border-radius: 16px;
  border: solid 1px #6e3694;
}
